// @flow
import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

type TEnergyClass = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G';

type Props = {
  energyClass: TEnergyClass,
  small: true | false,
  classes: any,
};

const styles = () => ({
  energyClassText: {
    color: '#FFF',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: 1,
  },
  energyClassTextSmall: {
    color: '#FFF',
    fontSize: '11px',
    fontWeight: 600,
    lineHeight: 1,
  },
  ingenDataText: {
    color: '#A8ABB2',
    fontSize: '12px',
    fontStyle: 'italic',
  },
  energyClassSubtextSmall: {
    color: '#FFF',
    fontSize: '8px',
    fontWeight: 600,
    lineHeight: 1,
  },
  energyClassSubtext: {
    color: '#FFF',
    fontSize: '10px',
    fontWeight: 600,
    lineHeight: 1,
  },
});

function EnergyClass(props: Props) {
  const EnergyClassToColors = {
    A2020: '#0b7a36',
    A2015: '#3ab54a',
    A2010: '#8bc63e',
    A: '#8bc63e',
    B: '#73a532',
    C: '#d09f0a',
    D: '#cb7a16',
    E: '#f15929',
    F: '#7a030f',
    G: '#552b5a',
  };
  const { classes, energyClass, small } = props;

  if (!Object.keys(EnergyClassToColors).includes(energyClass) || !energyClass) {
    return small ? (
      <Typography className={classes.ingenDataText}>Ingen data</Typography>
    ) : (
      <></>
    );
  }

  const energyClassText =
    energyClass.length > 1
      ? [energyClass[0], energyClass.slice(1)]
      : energyClass;

  return (
    <Container color={EnergyClassToColors[energyClass]} $small={small}>
      {Array.isArray(energyClassText) ? (
        <>
          <Typography
            className={
              small ? classes.energyClassTextSmall : classes.energyClassText
            }
          >
            {energyClassText[0]}
          </Typography>
          <Typography
            className={
              small
                ? classes.energyClassSubtextSmall
                : classes.energyClassSubtext
            }
          >
            {energyClassText[1]}
          </Typography>
        </>
      ) : (
        <Typography
          className={
            small ? classes.energyClassTextSmall : classes.energyClassText
          }
        >
          {energyClassText}
        </Typography>
      )}
    </Container>
  );
}

const Container = styled.div`
  align-items: center;
  background-color: ${props => `${props.color}`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: ${props => (props.$small ? '22px' : '40px')};
  width: ${props => (props.$small ? '22px' : '40px')};
`;

export default withStyles(styles)(EnergyClass);
