import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';

const useStyles = makeStyles(theme => {
  // console.log('theme:', theme);
  return {
    root: {
      maxWidth: '560px',
      alignSelf: 'center',
      margin: '16px',
      '@media (min-width: 960px)': {
        maxWidth: '540px',
      },
    },
    panel: {
      borderRadius: '10px',
      marginBottom: '15px',
      minHeight: '95px',
      maxWidth: '600px',
      '&:before': {
        height: 0,
      },
      '&:first-child': {
        borderRadius: '10px',
      },
      '&:last-child': {
        borderRadius: '10px',
      },
      boxShadow: '0px 0px 0px 0px',
      border: '1px solid #e8e8e8',
      '& .MuiIconButton-edgeEnd': {
        marginRight: '-25px',
      },
    },
    expanded: {
      margin: '0px',
    },
    heading: {
      fontSize: '13px',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontWeight: '500',
    },
    headingExpanded: {
      fontSize: '13px',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontWeight: '500',
      marginTop: '7px',
      transition: 'all 0.25s ease-in-out',
    },
    secondaryHeading: {
      fontSize: '13px',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontWeight: '400',
      color: '#7B7D82',
      lineHeight: '20px',
      overflow: 'hidden',
    },
    secondaryHeadingHidden: {
      opacity: 0,
    },
    rounded: {
      borderRadius: '20px',
    },
    content: {
      display: 'block',
      marginLeft: '30px',
      height: '55px',
      overflow: 'hidden',
      paddingTop: '18px',
      width: '100%',
    },
    icon: {
      paddingTop: '25px',
      marginLeft: '10px',
      transform: 'scale(-1, 1)',
    },
    iconColor: {
      color: '#13A970',
    },
    text: {
      '& .MuiTypography-body1': {
        padding: '0 8px 8px 8px',
        fontSize: '13px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: '400',
        color: '#7B7D82',
        lineHeight: '20px',
        marginLeft: '10px',
      },
    },
  };
});

export default function FaqAccordion() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <Accordion
        classes={{ root: classes.panel, expanded: classes.expanded }}
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className={classes.icon}>
            <ChatBubbleOutlineIcon
              className={classes.iconColor}
              fontSize="small"
            />
          </div>
          <div className={classes.content}>
            <Typography
              className={
                expanded === 'panel1'
                  ? classes.headingExpanded
                  : classes.heading
              }
            >
              Hvad er Min Klimaberegner?
            </Typography>
            <Typography
              className={
                expanded === 'panel1'
                  ? classes.secondaryHeadingHidden
                  : classes.secondaryHeading
              }
            >
              Min Klimaberegner hjælper boligejere ...
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.text}>
          <Typography>
            Min Klimaberegner hjælper boligejere og kommende boligejere med at
            gennemskue omkostningerne ved en energiforbedring af boligen.
            Gevinsten for klimaet og din økonomi ses straks i beregneren.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        classes={{ root: classes.panel, expanded: classes.expanded }}
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className={classes.icon}>
            <ChatBubbleOutlineIcon
              className={classes.iconColor}
              fontSize="small"
            />
          </div>
          <div className={classes.content}>
            <Typography
              className={
                expanded === 'panel2'
                  ? classes.headingExpanded
                  : classes.heading
              }
            >
              Hvordan bruges Min Klimaberegner?
            </Typography>
            <Typography
              className={
                expanded === 'panel2'
                  ? classes.secondaryHeadingHidden
                  : classes.secondaryHeading
              }
            >
              Du indtaster en adresse, hvorefter ...
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.text}>
          <Typography>
            Du indtaster en adresse, hvorefter Min Klimaberegner henter data fra
            boligens energimærke. Du vælger selv hvilke (og hvor mange)
            forbedringsforslag, du ønsker at gennemføre for boligen, og
            gevinsten ved forbedringen vises i beregneren.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        classes={{ root: classes.panel, expanded: classes.expanded }}
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className={classes.icon}>
            <ChatBubbleOutlineIcon
              className={classes.iconColor}
              fontSize="small"
            />
          </div>
          <div className={classes.content}>
            <Typography
              className={
                expanded === 'panel3'
                  ? classes.headingExpanded
                  : classes.heading
              }
            >
              Hvorfor har min bolig ikke et energimærke?
            </Typography>
            <Typography
              className={
                expanded === 'panel3'
                  ? classes.secondaryHeadingHidden
                  : classes.secondaryHeading
              }
            >
              Energimærker er lovpligtige og ...
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.text}>
          <Typography>
            Energimærker er lovpligtige og udarbejdes i forbindelse med
            boligsalg. Såfremt Min Klimaberegner ikke kan finde et energimærke,
            kan det skyldes, at boligen er købt, før Energimærket blev
            lovpligtigt i forbindelse med salg i 2010. Det kan også skyldes, at
            boligen ikke er omfattet af energiloven, og derfor ikke behøver et
            energimærke. Hvis du ved, at der for nylig er blevet udarbejdet et
            energimærke, kan du evt. prøve klimaberegneren igen på et senere
            tidspunkt.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        classes={{ root: classes.panel, expanded: classes.expanded }}
        expanded={expanded === 'panel4'}
        onChange={handleChange('panel4')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className={classes.icon}>
            <ChatBubbleOutlineIcon
              className={classes.iconColor}
              fontSize="small"
            />
          </div>
          <div className={classes.content}>
            <Typography
              className={
                expanded === 'panel4'
                  ? classes.headingExpanded
                  : classes.heading
              }
            >
              Hvem har lavet Min Klimaberegner?
            </Typography>
            <Typography
              className={
                expanded === 'panel4'
                  ? classes.secondaryHeadingHidden
                  : classes.secondaryHeading
              }
            >
              Min Klimaberegner er udviklet ...
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.text}>
          <Typography>
            Min Klimaberegner er udviklet af DomuTech A/S.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        classes={{ root: classes.panel, expanded: classes.expanded }}
        expanded={expanded === 'panel5'}
        onChange={handleChange('panel5')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className={classes.icon}>
            <ChatBubbleOutlineIcon
              className={classes.iconColor}
              fontSize="small"
            />
          </div>
          <div className={classes.content}>
            <Typography
              className={
                expanded === 'panel5'
                  ? classes.headingExpanded
                  : classes.heading
              }
            >
              Hvor kommer data fra?
            </Typography>
            <Typography
              className={
                expanded === 'panel5'
                  ? classes.secondaryHeadingHidden
                  : classes.secondaryHeading
              }
            >
              Min Klimaberegner henter boligens ...
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.text}>
          <Typography>
            Min Klimaberegner henter boligens rentable forbedringsforslag og
            anden data fra boligens energimærke. Det er Energistyrelsen, som har
            ansvaret for energimærkningsordningen.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        classes={{ root: classes.panel, expanded: classes.expanded }}
        expanded={expanded === 'panel6'}
        onChange={handleChange('panel6')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className={classes.icon}>
            <ChatBubbleOutlineIcon
              className={classes.iconColor}
              fontSize="small"
            />
          </div>
          <div className={classes.content}>
            <Typography
              className={
                expanded === 'panel6'
                  ? classes.headingExpanded
                  : classes.heading
              }
            >
              Hvorfor virker det ikke på min ejendom?
            </Typography>
            <Typography
              className={
                expanded === 'panel6'
                  ? classes.secondaryHeadingHidden
                  : classes.secondaryHeading
              }
            >
              Min Klimaberegner kan kun benyttes ...
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.text}>
          <Typography>
            Min Klimaberegner kan kun benyttes på ejendomme med et gyldigt
            energimærke. Kan klimaberegneren ikke finde boligens data, kan det
            skyldes, at boligen ikke har et energimærke, at boligen ikke har et
            gyldigt energimærke, eller at boligens energimærke ikke indeholder
            nogen rentable forbedringsforslag.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        classes={{ root: classes.panel, expanded: classes.expanded }}
        expanded={expanded === 'panel7'}
        onChange={handleChange('panel7')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className={classes.icon}>
            <ChatBubbleOutlineIcon
              className={classes.iconColor}
              fontSize="small"
            />
          </div>
          <div className={classes.content}>
            <Typography
              className={
                expanded === 'panel7'
                  ? classes.headingExpanded
                  : classes.heading
              }
            >
              Hvor meget CO2 binder et træ?
            </Typography>
            <Typography
              className={
                expanded === 'panel7'
                  ? classes.secondaryHeadingHidden
                  : classes.secondaryHeading
              }
            >
              Der er stor forskel på, hvor…
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.text}>
          <div>
            <Typography paragraph>
              Der er stor forskel på, hvor meget CO2 et træ kan binde. Mængden
              afhænger bl.a. af træsorten, størrelsen og rumtætheden. Til Min
              Klimaberegner har vi, i samarbejde med Palle Madsen, som er PhD i
              skovdyrkning samt seniorforsker og ejer af InNovaSilva ApS og
              tidl. professor ved Københavns Universitet, valgt at basere vores
              beregning på, hvor meget CO2 et gennemsnitligt bøgetræ binder om
              året.
            </Typography>
            <Typography paragraph>Vi arbejder med følgende model:</Typography>
            <div style={{ marginLeft: '10px' }}>
              <Typography gutterBottom>
                &#x2022; 1 træ binder 3,5 kg CO2 om året, svarende til 0,0035
                ton.
              </Typography>
              <Typography gutterBottom>
                &#x2022; 100 træer binder 350 kg CO2 om året, svarende til 0,35
                ton.
              </Typography>
              <Typography paragraph>
                &#x2022; 286 træer binder 1.000 kg CO2 om året, svarende til 1
                ton.
              </Typography>
            </div>
            <Typography paragraph>Kilde: Palle Madsen, Klimaskoven.</Typography>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
