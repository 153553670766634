// @flow
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'url-search-params-polyfill';
import 'intersection-observer';

import React from 'react';
import ReactDOM from 'react-dom';

import { ThemeProvider } from '@material-ui/styles';
import { createTheme , responsiveFontSizes } from '@material-ui/core';
import App from './App';
import 'typeface-roboto';

let theme = createTheme({
  typography: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    h1: {
      color: '#202124',
      fontSize: '26px',
      fontWeight: 500,
    },
    h2: {
      color: '#202124',
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: 1.25,
    },
    subtitle1: {
      color: '#7B7D82',
      fontSize: '13px',
      lineHeight: '20px',
    },
    subtitle2: {
      color: '#202124',
      fontSize: '13px',
    },
  },
});
theme = responsiveFontSizes(theme);

const rootElement = document.getElementById('root');
if (rootElement) {
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>,
    rootElement
  );
}
